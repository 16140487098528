<script setup lang="ts">
const i18n = useI18n()
const switchLocalePath = useSwitchLocalePath()
const availableLocales = computed(() => {
  return i18n.locales.value
    .map((i) => {
      if (typeof i === 'object')
        return { code: i.code, name: i.name }

      else
        return { code: i, name: i }
    })
    .filter(i => i.code !== i18n.locale.value)
})
</script>

<template>
  <div class="flex items-center gap-2">
    <Icon size="1.5rem" name="ic:baseline-language" />
    <NuxtLink v-for="locale in availableLocales" :key="locale.code" class="text-xl font-bold" :to="switchLocalePath(locale.code)">
      <div v-if="i18n.locale.value !== 'de'" v-umami="'language_german_button'">
        {{ $t('language.german') }}
      </div>
      <div v-else v-umami="'language_english_button'">
        {{ $t('language.english') }}
      </div>
    </NuxtLink>
  </div>
</template>
